<!--产学研对接内容页-->
<template>
  <!--路径s-->
  <div class="path">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="24" :md="22" :lg="20" >
        <span><router-link to="/"> 首页</router-link></span> &nbsp; &gt; &nbsp; <router-link to="/activity/">产学研对接</router-link> &nbsp; &gt; &nbsp;内容页
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>
  <!--路径s--> 
  <div class="box-p res">
    <el-row :gutter="0">
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :xs="24" :sm="24" :md="22" :lg="20">            
          <div v-loading="imgLoading">            
            <el-row :gutter="0" class="res-head">
              <el-col :sm="10" :md="10">
                <img v-if="imgData === null" src="@/assets/images/nopic1.jpg" />
                <el-carousel v-else>
                  <el-carousel-item v-for="item in imgData" :key="item">
                    <!-- <img :src="this.$api.serverUrl + item.url" /> -->
                    <el-image :src="this.$api.serverUrl + item.url"></el-image>
                  </el-carousel-item>
                </el-carousel>
              </el-col>
              <el-col :sm="14" :md="14" style="padding-left: 16px;">
                <div class="res-title">{{data.name}}</div>
                <div class="res-intro1 res-intro2">
                  <p>
                    <span>报名开始时间：<time>2022-07-13 09:00</time></span> <span>额定报名人数：<time>3人/企业</time></span> 
                  </p>
                    <p>
                    <span>报名截至时间：<time>2022-08-24 18:00</time></span> <span>活动剩余名额：<time>23人</time></span> 
                  </p>
                  <p>
                    <span>活动开始时间：<time>2022-08-26 09:00:00</time></span> <span>活动总计名额：<time>50人</time></span> 
                  </p>
                  <p>
                    <span>活动举办地点：昆明高新区中小企业公共服务平台（高新区昌源北路1389号航空航天及IT大厦A座3楼）</span> 
                  </p>
                </div>
                <div class="res-bottom">
                  <span>发布：{{formatDate(data.createDate)}}</span>
                </div>
                <div class="res-but">
                  <p>最终解释权归活动主办方所有。请注意报名剩余人数，以免错过本次活动。</p>
                  <el-button type="primary" plain>立即报名</el-button>
                </div>                         
              </el-col>
            </el-row>
            <div class="p_16">
              <el-tabs v-model="tabsactiveName" @tab-click="handleClick">
                <el-tab-pane label="活动详情" name="first">
                  <div v-html="data.content" class="res-content"></div>
                </el-tab-pane>
                <el-tab-pane label="相关附件" name="second">
                  <div class="res-content"> 相关附件, 相关附件, 相关附件。</div>
                </el-tab-pane>              
              </el-tabs>
            </div>
          </div>
      </el-col>
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
    </el-row>
  </div> 
</template>

<script>
import {formatDate} from '@/utils/datetime_utils.js'
export default {
  name: 'resource',  
  data () {
    return { 
      //用户审批状态(0:待审批1:通过2:不通过)
      useraudit: '', 
      imgLoading: false,    
      data: {
        id: '',
        name: '',
        content: '',
        //资源所有人Id
        createBy: '',
        //资源所有人(来源)
        createBy_nick_name: ''
      },
      imgData: null,
      apply: {
        restId: '',
        restName: '',
        createName: '',
        //资源所有人Id
        userId: '',
        //资源所有人
        username: ''
      },
      tabsactiveName: 'first'
    }
  },
  mounted () {
    var id = this.$route.query.id
    if(id === undefined || id === '') {
      this.$message.error('查看参数错误！')
      return
    }
    this.getData(id)

    const ui = this.$api.ui    
    if (ui !== null) {
      this.useraudit = ui.uaudit
      this.apply.createName = ui.nickName
    }
  },
  methods: {
    async getData (id) {
      this.imgLoading = true
      // status=1&
      const params = '?id='+ id
      const { data: ret } = await this.$http.get1(this.$api.resourceshow + params)      
      console.log('ret=', ret)
      if (ret.code === 1) {
        this.data = ret.data        
        // console.log('Data=', this.data)
        this.showImage(this.data.userId)
      } else {
        this.$message.error(ret.msg)        
      }
      this.imgLoading = false     
    },
    // 显示时间格式'yyyy-MM-dd hh:mm:ss'
    formatDate(time){
      return formatDate(time,'yyyy-MM-dd hh:mm')
    },
    showImage(val){
      if(val === null) {
        this.imgData = null
      } else {
        this.imgData = JSON.parse(val)        
      }   
    },
    async applyAdd () {
      this.$confirm('您确定要申请该项资源吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // const loading = this.$loading({
        //   lock: true,
        //   text: '正在加载中...',
        //   //background: 'rgba(0, 0, 0, 0.7)'
        // })        
        this.apply.restId = this.data.id
        this.apply.restName = this.data.name        
        this.apply.userId =this.data.createBy
        this.apply.username =this.data.createBy_nick_name
        console.log('applyform=', this.apply)
        // const { data: ret } = await this.$http.post(this.$api.orderAdd)
        // console.log('ret=', ret)
        // if (ret.code === 1) {
        //   this.$message.success(ret.msg)            
        // } else {
        //   this.$message.error(ret.msg)
        // }
        // loading.close()
      })
    }
  }
}
</script>

<style scoped>
  
</style>